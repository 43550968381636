import { ReduxState } from '../../AppLoader/types';

export const selectPopUpInstanceSpecificInfo = (state: ReduxState) =>
  state.popUps.instanceSpecificInfo;

export const selectPopUpsContext = (state: ReduxState) => state.popUps.context;

export const selectPopUpIsOpen = (popUpName: string) => (state: ReduxState) =>
  state.popUps.showPopUp && state.popUps.popUpName === popUpName;

export const selectPopUpsPlansAndPricingUpgradeInfo = (state: ReduxState) =>
  state.popUps.instanceSpecificInfo.plansAndPricingUpgradeInfo;
