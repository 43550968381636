import { mapValues } from 'lodash';
import { object, string, number, boolean, array, lazy } from 'yup';

const folderSchema = object({
  bins: lazy((obj) =>
    object(
      mapValues(obj, (value, key) => {
        if (key === value.uniqueId) {
          return object({
            dateUpdated: string().required(),
            description: string().nullable(),
            id: number().required(),
            isOwnedByMe: boolean().required(),
            isShared: boolean().required(),
            isUpdating: boolean(),
            itemCount: number().required(),
            name: string().required(),
            ownerName: string(),
            thumbnailUrl: string().required().nullable(),
            uniqueId: string().required(),
            contentIds: array().of(number()).defined(),
            stockItemIds: array().of(number()).defined(),
          });
        } else {
          throw new Error('key does not match uniqueId');
        }
      })
    ).required()
  ),
  hasEnterpriseSupport: boolean().required(),
  isInSharedFoldersBeta: boolean().required(),
  organizationName: string().required().nullable(),
});

const validateFoldersSchema = async (
  localStorageResult,
  schema = folderSchema
) => {
  const validSchema = await schema.validate(localStorageResult);
  return validSchema;
};

export default validateFoldersSchema;
