import AbstractAPI from '../../common/AbstractAPI';
import { HTTPMethod } from '../../common/Constants';
import SiteConstants from '../../common/SiteConstants/SiteConstants';
import { StockItem } from '../../common/types/StockItemTypes';
import { FOLDER_ACCESS_CONTROL } from './actions/FoldersActions';

class FoldersAPI extends AbstractAPI {
  static fetchAllFolders() {
    const options = this.buildFetchOptions(HTTPMethod.GET);

    return this.request(
      SiteConstants.buildInstance().getFoldersApiBasePath(),
      options
    );
  }

  static addNewFolderWithStockItems(
    folderName,
    accessControl = FOLDER_ACCESS_CONTROL.PRIVATE,
    stockItems = []
  ) {
    if (!Array.isArray(stockItems)) {
      stockItems = [stockItems];
    }

    const contentIds = stockItems.map((stockItem) => stockItem.contentId);

    const options = this.buildFetchOptions(HTTPMethod.POST, {
      name: folderName,
      accessControl: accessControl,
      contentIds,
    });

    return this.request(
      SiteConstants.buildInstance().getFoldersApiBasePath(),
      options
    );
  }

  static addStockItemToFolder(
    folderUniqueId: string,
    stockItems: StockItem | Array<StockItem>
  ) {
    if (!Array.isArray(stockItems)) {
      stockItems = [stockItems];
    }

    const contentIds = stockItems.map((stockItem) => stockItem.contentId);
    const options = this.buildFetchOptions(HTTPMethod.POST, {
      contentIds: contentIds,
    });

    return this.request(
      `${SiteConstants.buildInstance().getFoldersApiBasePath()}/${folderUniqueId}`,
      options
    );
  }

  static updateFolderName(folderUniqueId, name) {
    const options = this.buildFetchOptions(HTTPMethod.PATCH, { name });

    return this.request(
      `${SiteConstants.buildInstance().getFoldersApiBasePath()}/${folderUniqueId}`,
      options
    );
  }

  static updateFolderAccessControl(binUniqueId, accessControl) {
    const options = this.buildFetchOptions(HTTPMethod.PATCH, { accessControl });

    return this.request(
      `${SiteConstants.buildInstance().getFoldersApiBasePath()}/${binUniqueId}`,
      options
    );
  }

  static deleteFolder(uniqueId) {
    const options = this.buildFetchOptions(HTTPMethod.DELETE, {});

    return this.request(
      `${SiteConstants.buildInstance().getFoldersApiBasePath()}/${uniqueId}`,
      options
    );
  }

  static cloneFolder(folderUniqueId, name, accessControl) {
    const options = this.buildFetchOptions(HTTPMethod.POST, {
      name,
      accessControl,
    });

    return this.request(
      `${SiteConstants.buildInstance().getFoldersApiBasePath()}/${folderUniqueId}/clone`,
      options
    );
  }
}

export default FoldersAPI;
