import { createAction } from 'redux-actions';

import { showPopUp } from '../../../PopUps/actions/PopUpsActions';
import { CONFIRM_ACTION_MODAL } from '../../../PopUps/components/types';
import { notify } from '../../../ReactToastify/containers/ReactToastifyContainer';
import { StockItem } from '../../../common/types/StockItemTypes';
import FoldersAPI from '../FoldersAPI';
import { TYPES } from '../FoldersTypes';
import { FOLDERS_STORAGE_KEY } from '../middleware/persistFoldersMiddleware';
import validateFoldersSchema from '../utils/validateFoldersSchema';

export enum FOLDER_ACCESS_CONTROL {
  PRIVATE = 'private',
  SHARED = 'shared',
}

const addStockItemToFolderStarted = createAction(
  TYPES.ADD_STOCK_ITEM_TO_FOLDER_STARTED
);
export const addStockItemToFolderFailed = createAction(
  TYPES.ADD_STOCK_ITEM_TO_FOLDER_FAILED
);
const addStockItemToFolderCompleted = createAction(
  TYPES.ADD_STOCK_ITEM_TO_FOLDER_COMPLETED
);

const fetchAllFoldersStarted = createAction(TYPES.FETCH_ALL_FOLDERS_STARTED);
const fetchAllFoldersFailed = createAction(TYPES.FETCH_ALL_FOLDERS_FAILED);
export const fetchAllFoldersCompleted = createAction(
  TYPES.FETCH_ALL_FOLDERS_COMPLETED
);

export function fetchAllFolders() {
  return async (dispatch) => {
    dispatch(fetchAllFoldersStarted());

    const persistedFolders = window?.localStorage?.getItem(FOLDERS_STORAGE_KEY);
    if (persistedFolders) {
      const parsedFolders = JSON.parse(persistedFolders);
      try {
        const validSchema = await validateFoldersSchema(parsedFolders);
        return dispatch(fetchAllFoldersCompleted(validSchema));
      } catch {
        // if error is thrown, we'll clear localStorage and refetch data from API
        localStorage.removeItem(FOLDERS_STORAGE_KEY);
      }
    }

    return FoldersAPI.fetchAllFolders()
      .then((response) => response.json())
      .then((json) => dispatch(fetchAllFoldersCompleted(json)))
      .catch((e) => dispatch(fetchAllFoldersFailed(e)));
  };
}

export function addStockItemsToFolder(
  folderUniqueId: string,
  stockItems: StockItem | Array<StockItem>,
  stockItemInfo?: object
) {
  return (dispatch) => {
    dispatch(addStockItemToFolderStarted());

    return FoldersAPI.addStockItemToFolder(folderUniqueId, stockItems)
      .then((response) => response.json())
      .then((json) => {
        json.addedStockItemInfo = stockItemInfo;
        dispatch(addStockItemToFolderCompleted(json));
        notify(`Added to "${json.bins[folderUniqueId].name}"`);
      })
      .catch((e) => {
        dispatch(addStockItemToFolderFailed(e.json));
        notify('Error adding item to folder.');

        // We might be out of sync. Refresh the list of folders.
        dispatch(fetchAllFolders());
      });
  };
}

export const addNewFolderWithStockItemStarted = createAction(
  TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_STARTED
);
const addNewFolderWithStockItemFailed = createAction(
  TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_FAILED
);
export const addNewFolderWithStockItemCompleted = createAction(
  TYPES.ADD_NEW_FOLDER_WITH_STOCK_ITEM_COMPLETED
);

export function addNewFolderWithStockItem(
  folderName: string,
  stockItems: StockItem[],
  folderAccessControl?: FOLDER_ACCESS_CONTROL
) {
  return (dispatch) => {
    dispatch(addNewFolderWithStockItemStarted());

    return FoldersAPI.addNewFolderWithStockItems(
      folderName,
      folderAccessControl,
      stockItems
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          notify(`Folder ${folderName} was created`);
          dispatch(addNewFolderWithStockItemCompleted(json));
          dispatch(addStockItemToFolderCompleted(json));
        } else {
          dispatch(addNewFolderWithStockItemFailed(json));
          dispatch(
            showPopUp(CONFIRM_ACTION_MODAL, {
              title: 'Uh-Oh!',
              message: json.errMsgForUser,
              buttonText: 'OK',
            })
          );
        }
      })
      .catch((e) => dispatch(addNewFolderWithStockItemFailed(e)));
  };
}

const updateFolderNameStarted = createAction(TYPES.UPDATE_FOLDER_NAME_STARTED);
const updateFolderNameFailed = createAction(TYPES.UPDATE_FOLDER_NAME_FAILED);
const updateFolderNameCompleted = createAction(
  TYPES.UPDATE_FOLDER_NAME_COMPLETED
);

export function updateFolderName(folderUniqueId, name) {
  return (dispatch) => {
    dispatch(updateFolderNameStarted(folderUniqueId));

    return FoldersAPI.updateFolderName(folderUniqueId, name)
      .then((response) => response.json())
      .then((json) =>
        dispatch(updateFolderNameCompleted(json.bins[folderUniqueId]))
      )
      .catch(() => dispatch(updateFolderNameFailed(folderUniqueId)));
  };
}

const deleteFolderStarted = createAction(TYPES.DELETE_FOLDER_STARTED);
const deleteFolderFailed = createAction(TYPES.DELETE_FOLDER_FAILED);
const deleteFolderCompleted = createAction(TYPES.DELETE_FOLDER_COMPLETED);

export function deleteFolder(folderUniqueId) {
  return (dispatch) => {
    dispatch(deleteFolderStarted(folderUniqueId));

    return FoldersAPI.deleteFolder(folderUniqueId)
      .then((response) => response.json())
      .then(() => dispatch(deleteFolderCompleted(folderUniqueId)))
      .catch(() => dispatch(deleteFolderFailed(folderUniqueId)));
  };
}

const cloneFolderStarted = createAction(TYPES.CLONE_FOLDER_STARTED);
const cloneFolderFailed = createAction(TYPES.CLONE_FOLDER_FAILED);
const cloneFolderCompleted = createAction(TYPES.CLONE_FOLDER_COMPLETED);

export function cloneFolder(folderUniqueId, name, accessControl) {
  return (dispatch) => {
    dispatch(cloneFolderStarted(folderUniqueId));

    return FoldersAPI.cloneFolder(folderUniqueId, name, accessControl)
      .then((response) => response.json())
      .then((json) => {
        dispatch(
          cloneFolderCompleted({
            newBin: json.bins,
            oldBinUniqueId: folderUniqueId,
          })
        );
        notify(`Copied new Folder ${name}`);
      })
      .catch(() => {
        dispatch(cloneFolderFailed(folderUniqueId));
        notify(`Failed to copy new Folder ${name}`);
      });
  };
}

const updateFolderAccessControlStarted = createAction(
  TYPES.UPDATE_FOLDER_ACCESS_CONTROL_STARTED
);
const updateFolderAccessControlFailed = createAction(
  TYPES.UPDATE_FOLDER_ACCESS_CONTROL_FAILED
);
const updateFolderAccessControlCompleted = createAction(
  TYPES.UPDATE_FOLDER_ACCESS_CONTROL_COMPLETED
);

export function updateFolderAccessControl(
  folderUniqueId,
  folderName,
  accessControl
) {
  return (dispatch) => {
    dispatch(updateFolderAccessControlStarted(folderUniqueId));

    return FoldersAPI.updateFolderAccessControl(folderUniqueId, accessControl)
      .then((response) => response.json())
      .then((json) => {
        dispatch(updateFolderAccessControlCompleted(json.bins[folderUniqueId]));
        if (accessControl === FOLDER_ACCESS_CONTROL.SHARED) {
          notify(`Folder ${folderName} has been shared with your organization`);
        }
      })
      .catch(() => {
        dispatch(updateFolderAccessControlFailed(folderUniqueId));
        notify(
          `Failed to update access control of the ${folderName} folder to ${accessControl}`
        );
      });
  };
}
