import React, { Component } from 'react';
import { toast, ToastContainer, ToastPosition } from 'react-toastify';

import './ReactToastifyContainer.less';
import 'react-toastify/dist/ReactToastify.css';

class ReactToastifyContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ToastContainer hideProgressBar autoClose={3000} />;
  }
}

export function notify(
  message,
  autoClose = true,
  closeOnClick = true,
  draggable = true,
  position: ToastPosition = 'top-right'
) {
  toast(message, {
    position,
    autoClose: autoClose === true ? 5000 : autoClose,
    closeOnClick,
    draggable,
  });
}

export function error(
  message,
  position: ToastPosition = 'top-right',
  autoClose = true,
  closeOnClick = true,
  draggable = true
) {
  toast.error(message, {
    position,
    autoClose: autoClose === true ? 5000 : autoClose,
    closeOnClick,
    draggable,
  });
}

export default ReactToastifyContainer;
